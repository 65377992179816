export const fakeAuth = {
	isAuthenticate() {
		let token = localStorage.getItem('token');
		return token !== null;
	},
	signOut() {
		localStorage.clear();
	},
	getToken() {
		let token = localStorage.getItem('token');
		return token;
	},
	newUser() {
		let user = localStorage.getItem('user');
		let newUser = user !== null && JSON.parse(user).newUser;
		return newUser === '0';
	},
	getPermission() {
		let permissions = localStorage.getItem('permission');
		let permission = permissions !== null && JSON.parse(permissions);
		return permission;
	},
	getUser() {
		let user = localStorage.getItem('user');
		return JSON.parse(user);
	},
};

export const getQueryStringValue = (key) => {
	return decodeURIComponent(
		window.location.search.replace(
			new RegExp(
				'^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
				'i'
			),
			'$1'
		)
	);
};

export const getLogoutUrl = () => {
	let origin_url = localStorage.getItem('origin_url');
	try {
		if (origin_url !== null) {
			return `http://${origin_url}/logout`;
		} else {
			return 'http://newapp.namastecredit.com';
		}
	} catch (error) {
		return 'http://newapp.namastecredit.com';
	}
};
