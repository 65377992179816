import React from 'react';

export function UserImage() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#E8F1F7'
					d='M27.498 14.017c0 7.587-6.15 13.737-13.737 13.737-7.588 0-13.739-6.15-13.739-13.737C.022 6.429 6.173.278 13.761.278c7.587 0 13.737 6.151 13.737 13.739'
				/>
				<path
					fill='#FFF'
					d='M17.625 11.116c0-2.393-1.73-4.333-3.865-4.333-2.134 0-3.864 1.94-3.864 4.333 0 2.394 1.73 4.334 3.864 4.334 2.135 0 3.865-1.94 3.865-4.334'
				/>
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M17.625 11.116c0-2.393-1.73-4.333-3.865-4.333-2.134 0-3.864 1.94-3.864 4.333 0 2.394 1.73 4.334 3.864 4.334 2.135 0 3.865-1.94 3.865-4.334z'
				/>
				<path fill='#FFF' d='M13.76 15.45c-4.335 0-7.85 2.656-7.85 5.8h15.7c0-3.144-3.514-5.8-7.85-5.8' />
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M13.76 15.45c-4.335 0-7.85 2.656-7.85 5.8h15.7c0-3.144-3.514-5.8-7.85-5.8z'
				/>
				<path fill='#E8F1F7' d='M13.207 16.21c-2.774.128-5.63 1.658-6.326 4.391h4.121l2.205-4.391z' />
				<path fill='navy' d='M11.543 20.601l1.664-3.323-1.14 3.323z' />
			</g>
		</svg>
	);
}

export function Email() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#E8F1F7'
					d='M27.588 13.854c0 7.588-6.15 13.738-13.737 13.738-7.588 0-13.739-6.15-13.739-13.738C.112 6.267 6.263.116 13.851.116c7.587 0 13.737 6.151 13.737 13.738'
				/>
				<path
					fill='#FFF'
					d='M20.56 18.846H7.14a.154.154 0 0 1-.154-.154V9.017c0-.085.069-.154.154-.154H20.56c.086 0 .155.069.155.154v9.675a.154.154 0 0 1-.155.154'
				/>
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M20.56 18.846H7.14a.154.154 0 0 1-.154-.154V9.017c0-.085.069-.154.154-.154H20.56c.086 0 .155.069.155.154v9.675a.154.154 0 0 1-.155.154z'
				/>
				<path fill='#E7EFF9' d='M6.987 18.692l5.336-5.717h3.032l5.204 5.717' />
				<path stroke='#5C79B5' strokeWidth='.5' d='M6.987 18.692l5.336-5.717h3.032l5.204 5.717' />
				<path fill='#FFF' d='M7.108 9.037l6.742 5.092 6.71-5.143' />
				<path stroke='#5C79B5' strokeWidth='.5' d='M7.108 9.037l6.742 5.092 6.71-5.143' />
				<path fill='navy' d='M12.498 12.194l1.356 1.024 1.349-1.034' />
			</g>
		</svg>
	);
}

export function Mobile() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#E8F1F7'
					d='M27.737 13.743c0 7.587-6.151 13.738-13.738 13.738C6.41 27.48.26 21.33.26 13.743.26 6.155 6.411.005 14 .005c7.587 0 13.738 6.15 13.738 13.738'
				/>
				<path
					fill='#FFF'
					d='M18.074 21.424H9.923a.562.562 0 0 1-.562-.562V6.678c0-.31.252-.562.562-.562h8.151c.31 0 .562.252.562.562v14.184c0 .31-.251.562-.562.562'
				/>
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M18.074 21.424H9.923a.562.562 0 0 1-.562-.562V6.678c0-.31.252-.562.562-.562h8.151c.31 0 .562.252.562.562v14.184c0 .31-.251.562-.562.562z'
				/>
				<path
					fill='#FFF'
					d='M18.042 21.424H9.955a.594.594 0 0 1-.594-.594v-1.962h9.275v1.962a.594.594 0 0 1-.594.594'
				/>
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M18.042 21.424H9.955a.594.594 0 0 1-.594-.594v-1.962h9.275v1.962a.594.594 0 0 1-.594.594z'
				/>
				<path
					fill='#FFF'
					d='M9.955 6.061h8.087c.328 0 .594.266.594.594v1.178H9.361V6.655c0-.328.266-.594.594-.594'
				/>
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M9.955 6.061h8.087c.328 0 .594.266.594.594v1.178H9.361V6.655c0-.328.266-.594.594-.594z'
				/>
				<path
					fill='#E8F1F7'
					d='M18.263 7.407H9.828v-.633c0-.158.129-.286.287-.286h7.862c.158 0 .286.128.286.286v.633z'
				/>
				<path fill='navy' d='M14.634 20.107a.635.635 0 1 1-1.27 0 .635.635 0 0 1 1.27 0' />
				<path fill='#E8F1F7' d='M18.497 18.724l-4.111-4.923-4.891-5.845 9.002-.005z' />
			</g>
		</svg>
	);
}

export function Pincode() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#E8F1F7'
					d='M27.678 13.786c0 7.587-6.15 13.738-13.738 13.738-7.587 0-13.738-6.15-13.738-13.738C.202 6.199 6.353.048 13.94.048c7.588 0 13.738 6.15 13.738 13.738'
				/>
				<path fill='#FFF' d='M21.8 21.437H6.08l2.99-4.034h9.701z' />
				<path stroke='#5C79B5' strokeWidth='.5' d='M21.8 21.437H6.08l2.99-4.034h9.701z' />
				<path
					fill='#E8F1F7'
					d='M12.024 19.946H8.13l1.485-1.98h3.773zM15.947 19.946h3.893l-1.485-1.98h-3.773z'
				/>
				<path
					fill='#FFF'
					d='M18.226 10.455c.045 3.061-4.286 8.948-4.286 8.948s-4.332-5.887-4.286-8.948c.036-2.386 1.92-4.32 4.286-4.32 2.368 0 4.252 1.934 4.286 4.32'
				/>
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M18.226 10.455c.045 3.061-4.286 8.948-4.286 8.948s-4.332-5.887-4.286-8.948c.036-2.386 1.92-4.32 4.286-4.32 2.368 0 4.252 1.934 4.286 4.32z'
				/>
				<path
					fill='#5C79B5'
					d='M17.039 10.404c-.12 2.698-3.099 6.918-3.099 6.918s-3.04-3.889-3.176-6.918c-.078-1.731 1.405-3.137 3.137-3.137 1.733 0 3.212 1.46 3.138 3.137'
				/>
				<path fill='#E8F1F7' d='M11.523 20.869H7.469l.486-.608h3.96z' />
				<path fill='navy' d='M16.518 20.869h4.054l-.486-.608h-3.961z' />
				<path fill='#E8F1F7' d='M15.43 10.076a1.49 1.49 0 1 1-2.979 0 1.49 1.49 0 0 1 2.979 0' />
			</g>
		</svg>
	);
}

export function Password() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
			<g fill='none' fillRule='evenodd'>
				<path
					fill='#E8F1F7'
					d='M27.82 14.174c0 7.623-6.18 13.804-13.803 13.804-7.624 0-13.804-6.18-13.804-13.804C.213 6.55 6.393.37 14.017.37c7.623 0 13.804 6.18 13.804 13.804'
				/>
				<path
					fill='#FFF'
					d='M21.644 19.023c-.098-.09-1.066-.027-1.066-.027l-.01-1.025-.474-.541-1.123-.019v-.001c-.005.015-.001-1.127-.001-1.127l-.524-.38s-1.019-.03-1.027-.037l-.082-1.047-.944-1.117a5.485 5.485 0 0 0 .514-2.312 5.511 5.511 0 0 0-5.512-5.51 5.51 5.51 0 1 0-.002 11.02 5.48 5.48 0 0 0 2.358-.536l6.27 6.31 2.655.006.004-2.68s-.417-.406-1.036-.977zm-11.303-7.361a1.31 1.31 0 0 1-1.306-1.315c0-.726.585-1.315 1.306-1.315.722 0 1.308.589 1.308 1.315s-.586 1.315-1.308 1.315z'
				/>
				<path fill='#FFF' d='M14.73 15.54c-.5.437-1.077.782-1.71 1.016l6.207 6.124h2.613l-7.11-7.14z' />
				<path fill='#E8F1F7' d='M13.693 16.427l1.207-.776 7.15 7.029H20.07l-6.378-6.253z' />
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M21.644 19.023c-.098-.09-1.066-.027-1.066-.027l-.01-1.025-.474-.541-1.123-.019v-.001c-.005.015-.001-1.127-.001-1.127l-.524-.38s-1.019-.03-1.027-.037l-.082-1.047-.944-1.117a5.485 5.485 0 0 0 .514-2.312 5.511 5.511 0 0 0-5.512-5.51 5.51 5.51 0 1 0-.002 11.02 5.48 5.48 0 0 0 2.358-.536l6.27 6.31 2.655.006.004-2.68s-.417-.406-1.036-.977zm-11.303-7.361a1.31 1.31 0 0 1-1.306-1.315c0-.726.585-1.315 1.306-1.315.722 0 1.308.589 1.308 1.315s-.586 1.315-1.308 1.315z'
				/>
				<path
					fill='#E8F1F7'
					d='M16.57 11.107c0-2.55-2.187-4.616-4.886-4.616-2.7 0-4.888 2.066-4.888 4.616 0 2.549 9.775 2.549 9.775 0z'
				/>
				<path fill='navy' d='M10.462 11.247a.786.786 0 1 1 0-1.571.786.786 0 0 1 0 1.571z' />
				<path
					stroke='#5C79B5'
					strokeWidth='.5'
					d='M14.928 15.655a5.54 5.54 0 0 1-1.717 1l6.233 6.025h2.625l-7.141-7.025z'
				/>
			</g>
		</svg>
	);
}
