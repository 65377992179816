import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { getWhiteLabel, buttonTextColor } from '../../utils/helper/getWhitelabel';

export default class CustomButton extends PureComponent {
	render() {
		const buttonColor = getWhiteLabel();
		const textolor = buttonTextColor();
		return (
			<Button
				shape={this.props.shape}
				size={this.props.size}
				type={this.props.type && this.props.type}
				loading={this.props.loading && this.props.loading}
				onClick={this.props.onClick && this.props.onClick}
				className={this.props.className}
				disabled={this.props.disabled}
				style={{
					color: `${textolor}`,
					backgroundImage: `linear-gradient(to bottom, ${buttonColor})`
				}}
			>
				{this.props.children}
			</Button>
		);
	}
}
