import React from 'react';
import Lottie from 'lottie-react-web';

import NCLogo from '../../constants/NCLogo.json';

export default function NCLoader(props) {
	return (
		<Lottie
			options={{
				animationData: NCLogo
			}}
			width={500}
		/>
	);
}
