import React, { Component, Fragment } from 'react';
import {
	Button,
	Upload,
	InputNumber,
	Input,
	Row,
	Col,
	DatePicker,
	Form,
	Select,
	Badge,
	message,
	Checkbox,
	Modal,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { fakeAuth } from '../../utils/helper/RouteHelper';
import { getUrl } from '../../utils/helper/baseUrls';
import './index.scss';
import CustomButton from './../CustomButton';
import { getLoanProducts, applyLoan } from './../../../redux/actions/loan';
import { getCityState } from '../../../redux/actions';
import { login, verifyOtp, trigerOtpSend } from './../../../redux/actions';
import { createUser } from './../../../redux/actions/index';
import images from '../../utils/helper/imageurls';
import Footer from './Footer';

const { Option } = Select;
const formItemLayout = {
	labelCol: {
		span: 10,
	},
	wrapperCol: {
		span: 14,
	},
};
const text = [
	{
		image: images['Icon1'],
		heading: '1. Fill-in the application form',
		text: 'Enter your personal and business-related details on the Business Loan application form',
	},
	{
		image: images['Icon2'],
		heading: '2. Upload Documents',
		text: 'Attach your bank statements for the last 1 year along with PAN and Address proof',
	},
	{
		image: images['Icon3'],
		heading: '3. Automatic Lender Matching',
		text: 'Our auto-matching engine will find out the best loan offer & lender basis uploaded documents',
	},
	{
		image: images['Icon4'],
		heading: '4. Receive a call from NC account manager',
		text: 'Our team will contact you to complete documentation and disbursement',
	},
];

class BizLanding extends Component {
	state = {
		currentStep: 1,
		isLoading: false,
		KYC: [],
		others: [],
		financials: [],
		district: '',
		locality: '',
		_state: '',
		userid: '',
		otpDisabled: true,
		userCreated: false,
		isPinLoading: false,
		isValidOtp: false,
		isPinValid: false,
		formData: {},
		Step1: [
			{
				default_value: null,
				key: 'loan_amount',
				label: 'Loan Amount',
				mandatory: true,
				option: [],
				sequence: 1,
				switch_default_value: 'Lakhs',
				switch_key: 'loan_amount_um',
				type: 'input',
				validation: [{ type: 'number', min: 100000, max: 999999999 }],
				visibility: true,
				placeholder: '00,00,000',
			},
			{
				default_value: '',
				key: 'dfirstname0',
				label: "Applicant's Name",
				mandatory: true,
				option: [],
				sequence: 2,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [],
				visibility: true,
			},
			{
				default_value: null,
				key: 'ddob0',
				label: "Applicant's Date Of Birth",
				mandatory: true,
				option: [{ key: 1, value: 'months' }, { key: 2, value: 'years' }],
				sequence: 3,
				switch_default_value: 'months',
				switch_key: 'business_started_um',
				type: 'date',
				validation: [],
				visibility: true,
			},
			{
				default_value: '',
				key: 'business_name',
				label: 'Existing Business Name',
				mandatory: false,
				option: [],
				sequence: 5,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [],
				visibility: false,
			},
			{
				default_value: null,
				key: 'business_type',
				label: 'Business Entity Type',
				mandatory: true,
				option: [{ id: 1, value: 'Sole Proprietorship' }, { id: 2, value: 'Partnership' }],
				sequence: 5,
				switch_default_value: '',
				switch_key: '',
				type: 'dropdown',
				validation: [],
				visibility: true,
			},
			{
				default_value: '',
				key: 'pincode',
				label: 'Pin Code',
				mandatory: true,
				option: [],
				sequence: 6,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [{ type: 'number', length: 6 }],
				visibility: true,
			},
			{
				default_value: '',
				key: 'city',
				label: 'City',
				mandatory: true,
				option: [],
				sequence: 7,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [],
				visibility: true,
				disabled: true,
			},
			{
				default_value: '',
				key: 'state',
				label: 'State',
				mandatory: true,
				option: [],
				sequence: 8,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [],
				visibility: true,
				disabled: true,
			},
		],

		Step2: [
			{
				default_value: '',
				key: 'emi_obligation',
				label: 'Current Monthly EMI',
				mandatory: true,
				option: [],
				sequence: 9,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [],
				visibility: true,
			},
			{
				default_value: null,
				key: 'annual_revenue',
				label: 'Annual Turnover',
				mandatory: true,
				option: [],
				sequence: 10,
				switch_default_value: 'Lakhs',
				switch_key: 'revenue_um',
				type: 'input',
				validation: [{ type: 'number', min: 100000, max: 200000000 }],
				visibility: true,
			},
			{
				default_value: '',
				key: 'gstin',
				label: 'Business GSTIN',
				mandatory: false,
				option: [],
				sequence: 11,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [{ type: 'alpha', min: 0, max: 20 }],
				visibility: true,
			},
			{
				default_value: null,
				key: 'business_started',
				label: 'Business Start Year',
				mandatory: true,
				option: [{ key: 1, value: 'months' }, { key: 2, value: 'years' }],
				sequence: 12,
				switch_default_value: 'months',
				switch_key: 'business_started_um',
				type: 'date',
				validation: [],
				visibility: true,
			},
			{
				default_value: null,
				key: 'business_industry_type',
				label: 'Business Industry',
				mandatory: true,
				option: [
					{ id: 1, value: 'Accomodation and Food Services' },
					{ id: 2, value: 'Administrative, Support, Waste Management & Remediation Services' },
					{ id: 3, value: 'Agriculture, Forestry, Fishing & Hunting' },
					{ id: 4, value: 'Arts, Entertainment & Recreation' },
					{ id: 5, value: 'Construction' },
					{ id: 6, value: 'Educational Services' },
					{ id: 7, value: 'Finance and Insurance' },
					{ id: 8, value: 'Health Care and Social Assistance' },
					{ id: 9, value: 'Information Technology' },
					{ id: 10, value: 'Manufacturing' },
					{ id: 11, value: 'Mining, Quarrying and Oil & Gas Extraction' },
					{ id: 12, value: 'Scientific & Technical Services' },
					{ id: 13, value: 'Public Administration' },
					{ id: 14, value: 'Real Estate and Rental Leasing' },
					{ id: 15, value: 'Research & Development' },
					{ id: 16, value: 'Retail Trade' },
					{ id: 17, value: 'Transportation And Warehousing' },
					{ id: 18, value: 'Utilities' },
					{ id: 19, value: 'Wholesale Trade' },
					{ id: 20, value: 'Other Services' },
					{ id: 21, value: 'Professional Chartered Accountants' },
					{ id: 22, value: 'Professional Doctors' },
					{ id: 23, value: 'Professional Lawyers' },
					{ id: 24, value: 'Professional Architects' },
				],
				sequence: 13,
				switch_default_value: '',
				switch_key: '',
				type: 'dropdown',
				validation: [],
				visibility: true,
			},
			{
				default_value: '',
				key: 'email',
				label: 'Email',
				mandatory: true,
				option: [],
				sequence: 4,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [],
				visibility: true,
			},
			{
				default_value: '',
				key: 'mobile',
				label: 'Phone Number',
				mandatory: true,
				option: [],
				sequence: 4,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [],
				visibility: true,
			},
			{
				default_value: '',
				key: 'button',
				label: '.',
				mandatory: true,
				option: [],
				sequence: 4,
				switch_default_value: '',
				switch_key: '',
				type: 'button',
				validation: [],
				visibility: true,
			},
			{
				default_value: null,
				key: 'otp',
				label: 'Enter OTP',
				mandatory: false,
				option: [],
				sequence: 1,
				switch_default_value: 'Lakhs',
				switch_key: 'loan_amount_um',
				type: 'input',
				validation: [],
				visibility: true,
			},
		],

		Step3: [
			{
				default_value: null,
				key: 'unsecuredLoan_count',
				label: 'How many loans do you have?',
				mandatory: true,
				option: [],
				sequence: 16,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [{ type: 'number', min: 0, max: 10 }],
				visibility: true,
			},
			{
				default_value: null,
				key: 'emiBounce_count',
				label: 'How many EMI bounces do you have in past 6 months',
				mandatory: true,
				option: [],
				sequence: 17,
				switch_default_value: '',
				switch_key: '',
				type: 'input',
				validation: [{ type: 'number', min: 0, max: 10 }],
				visibility: true,
			},

			{
				default_value: '',
				key: 'fs_upload',
				label: 'Bank Statements',
				mandatory: false,
				option: [],
				sequence: 3,
				switch_default_value: '',
				switch_key: '',
				type: 'upload',
				validation: [],
				visibility: true,
			},
			{
				default_value: '',
				key: 'bd_upload',
				label: '* PAN Card',
				mandatory: true,
				option: [],
				sequence: 16,
				switch_default_value: '',
				switch_key: '',
				type: 'upload',
				validation: [],
				visibility: true,
			},
			{
				default_value: '',
				key: 'cl_upload',
				label: 'Address Proof',
				mandatory: false,
				option: [],
				sequence: 3,
				switch_default_value: '',
				switch_key: '',
				type: 'upload',
				validation: [],
				visibility: true,
			},
			{
				default_value: 'Not Sure',
				key: 'cibil_remarks',
				label: 'What is your CIBIL defaulter status ?',
				mandatory: true,
				option: [
					{ value: 'Non-CIBIL defaulter(Score above 700)', id: 'Non-CIBIL defaulter(Score above 700)' },
					{ value: 'Not Sure', id: 'Not Sure' },
					{ value: 'CIBIL defaulter(Score below 700)', id: 'CIBIL defaulter(Score below 700)' },
				],
				sequence: 1,
				switch_default_value: '',
				switch_key: '',
				type: 'dropdown',
				validation: [],
				visibility: true,
			},
			{
				default_value: null,
				key: 'CIBIL_check',
				label: 'Permission to check cibil score',
				mandatory: false,
				option: [],
				sequence: 1,
				switch_default_value: '',
				switch_key: '',
				type: 'check',
				validation: [],
				visibility: true,
			},
			{
				default_value: null,
				key: 'check2',
				label: 'Terms & Condition apply',
				mandatory: false,
				option: [],
				sequence: 1,
				switch_default_value: '',
				switch_key: '',
				type: 'check',
				validation: [],
				visibility: true,
			},
		],
	};

	getButton = (item) => {
		let mObj = this.props.form.getFieldsValue();
		return (
			<Form.Item className='label' colon={false} label={item.label} {...formItemLayout}>
				{this.props.form.getFieldDecorator(item.key, {
					rules: this.getRules(item),
				})(
					<Button
						id={item.key}
						disabled={item.disabled && item.disabled}
						style={{ backgroundColor: 'darkgrey' }}
						onClick={() => this.props.trigerOtpSend({ mobile: mObj.mobile })}
					>
						Get OTP
					</Button>
				)}
			</Form.Item>
		);
	};

	getFormBody(item) {
		switch (item.type) {
			case 'input':
				return this.getTextField(item);
			case 'dropdown':
				return this.getDropdown(item);
			case 'radio':
				return this.getRadio(item);
			case 'upload':
				return this.getUpload(item);
			case 'switch':
				return this.getSwitch(item);
			case 'date':
				return this.getDate(item);
			case 'button':
				return this.getButton(item);
			case 'check':
				return this.getCheckbox(item);
			default:
				return this.getTextField(item);
		}
	}

	getMultiple = (item) => {
		let isMultiple = Object.keys(item.multiple).length > 0;
		return (
			<Fragment>
				{Object.keys(item.multiple).map((multipleKeys, index) => {
					let data = item.multiple[multipleKeys];
					return (
						<Row className='multiple' key={index} gutter={10}>
							{data &&
								data.map((subitem, subKey) => {
									if (subitem.visibility === false) return null;
									return (
										<Col
											key={subKey}
											md={{ span: 8 }}
											lg={{ span: 8 }}
											sm={{ span: 24 }}
											xs={{ span: 24 }}
											className={'inputRows'}
										>
											{this.getFormBody(subitem)}
										</Col>
									);
								})}
						</Row>
					);
				})}
				{this.props.match.params.type === 'edit' && item.label === 'director_details' && (
					<Row type='flex' justify='end'>
						<CustomButton id={item.key} onClick={() => this.props.addMore()}>
							{isMultiple ? 'Add More' : 'Add'}
						</CustomButton>
					</Row>
				)}
			</Fragment>
		);
	};

	getTextField = (item) => {
		if (item.validation && item.validation[0] && item.validation[0].type === 'number') {
			let min = item.validation && item.validation[0] && item.validation[0].min;
			let max = item.validation && item.validation[0] && item.validation[0].max;
			return (
				<Form.Item className='label' colon={false} label={item.label} {...formItemLayout} hasFeedback>
					{this.props.form.getFieldDecorator(item.key, {
						getValueFromEvent: (e) => {
							if (e !== undefined) {
								const convertedValue = Number(e);
								if (isNaN(convertedValue)) {
									return Number(this.props.form.getFieldValue(item.key));
								} else {
									return convertedValue;
								}
							}
						},
						initialValue: typeof item.default_value === 'string' ? null : item.default_value,
						rules: this.getRules(item),
					})(
						item.key !== 'pincode' ? (
							<InputNumber
								style={{ width: '100%' }}
								min={min}
								max={max}
								name={item.key}
								formatter={(value) => {
									if (
										value.length !== 0 &&
										typeof value !== 'number' &&
										value.match(/[a-zA-Z]/) === null
									) {
										return new Intl.NumberFormat('en-IN', {
											maximumSignificantDigits: 2,
											maximumFractionDigits: 6,
										}).format(value);
									}
								}}
								placeholder={item.key === 'loan_amount' ? item.placeholder : item.label}
								onChange={item.key === 'pincode' && this.pinCodeChange}
							/>
						) : (
							<InputNumber
								style={{ width: '100%' }}
								min={min}
								max={max}
								name={item.key}
								placeholder={item.key === 'loan_amount' ? item.placeholder : item.label}
								onChange={item.key === 'pincode' && this.pinCodeChange}
							/>
						)
					)}
				</Form.Item>
			);
		} else {
			return (
				<Form.Item
					className='label'
					colon={false}
					label={item.label}
					validateStatus={
						item.key === 'city' || item.key === 'state'
							? this.state.isPinLoading
								? 'validating'
								: null
							: null
					}
					{...formItemLayout}
					hasFeedback
				>
					{this.props.form.getFieldDecorator(item.key, {
						initialValue:
							item.key === 'state'
								? this.state._state && this.state._state
								: item.key === 'city'
								? this.state.district
								: item.default_value && item.default_value,
						rules: this.getRules(item),
					})(
						<Input
							disabled={item.disabled && item.disabled}
							type={'text'}
							min={0}
							name={item.key}
							placeholder={item.label}
							onChange={item.key === 'otp' ? this.handleOtpChange : () => {}}
						/>
					)}
				</Form.Item>
			);
		}
	};

	getDate = (item) => {
		let default_value = item.default_value && moment(item.default_value).format('DD/MM/YYYY');
		return (
			<Form.Item className='label' colon={false} label={item.label} {...formItemLayout} hasFeedback>
				{this.props.form.getFieldDecorator(item.key, {
					initialValue:
						item.default_value !== null && item.default_value !== ''
							? moment(default_value, 'YYYY/MM/DD')
							: null,
					rules: this.getRules(item),
				})(
					<DatePicker
						disabledDate={(current) => {
							return moment().add(0, 'days') <= current;
						}}
						name={item.key}
						style={{ width: '100%' }}
					/>
				)}
			</Form.Item>
		);
	};

	getCheckbox = (item) => {
		return (
			<Form.Item className='label' colon={false} label={item.label} {...formItemLayout}>
				{this.props.form.getFieldDecorator(item.key, {
					rules: this.getRules(item),
				})(<Checkbox onChange={this.onCheckChange} />)}
			</Form.Item>
		);
	};

	getDropdown = (item) => {
		return (
			<Form.Item className='label' colon={false} label={item.label} {...formItemLayout} hasFeedback>
				{this.props.form.getFieldDecorator(item.key, {
					initialValue: item.default_value,
					rules: this.getRules(item),
				})(
					<Select
						name={item.key}
						disabled={item.disabled && item.disabled}
						style={{ width: '100%' }}
						loading={item.isLoading && item.isLoading}
					>
						{item.option.map((item, index) => (
							<Option key={index} value={item.id}>
								{item.value}
							</Option>
						))}
					</Select>
				)}
			</Form.Item>
		);
	};

	onHandleUploadChange = (info, item) => {
		let KYC = [...this.state.KYC],
			others = [...this.state.others],
			financials = [...this.state.financials];
		if (info.file.status === 'done') {
			const response = info.file.response.files;
			if (item.key === 'bd_upload') {
				response.forEach((item) => {
					item.value = 4;
					KYC.push(item);
				});
			} else if (item.key === 'cl_upload') {
				response.forEach((item) => {
					item.value = 134;
					others.push(item);
				});
			} else if (item.key === 'fs_upload') {
				response.forEach((item) => {
					item.value = 6;
					financials.push(item);
				});
			}
			this.setState({
				KYC,
				others,
				financials,
			});
		}
	};

	getUpload = (item) => {
		let userid = this.state.userid && this.state.userid;
		//let editUrl = `${getUrl()}/loanDocumentUpload?userid=${userid}`;
		const uploadProps = {
			name: 'document',
			multiple: true,
			action: `${getUrl()}/loanDocumentUpload`, //Api for posting the docs
			headers: {
				Authorization: `Bearer ${fakeAuth.getToken()}`,
				Accept: 'application/json, text/plain, */*',
			},
			data: {
				userid,
			},
		};

		return (
			<>
				<Form.Item className='label' colon={false} label={<span>{item.label}</span>} {...formItemLayout}>
					<Upload.Dragger
						{...uploadProps}
						accept='.doc,.docx,.pdf,.png,.jpg,.svg'
						listType='text'
						onChange={(e) => this.onHandleUploadChange(e, item)}
					>
						<p className='drag-drop-text'>Upload.</p>
					</Upload.Dragger>
				</Form.Item>
			</>
		);
	};
	getRules = (item) => {
		let rules = [];
		if (item.key === 'business_name') {
			rules.push(
				{
					required: true,
					message: `Kindly input ${item.label}`,
				},
				{
					pattern: /^[a-z\d\-_\s]+$/i,
					message: `Invalid ${item.label}`,
				}
			);
		} else if (item.key === 'email') {
			rules.push(
				{
					required: true,
					message: `Kindly input ${item.label}`,
				},
				{
					type: 'email',
					message: `invalid ${item.label}`,
				}
			);
		} else if (item.key === 'mobile') {
			rules.push(
				{
					required: true,
					message: `Kindly input ${item.label}`,
				},
				{
					pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
					message: `Invalid ${item.label}`,
				}
			);
		} else if (item.key === 'gstin') {
			rules.push(
				{
					required: true,
					message: `Kindly input ${item.label}`,
				},
				{
					pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
					message: 'Invalid GST!',
				}
			);
		} else if (item.key === 'business_started') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'residence_type') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'office_type') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'state') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'pincode') {
			rules.push(
				{
					required: true,
					message: `Kindly input ${item.label}`,
				},
				{
					pattern: /^[1-9][0-9]{5}$/,
					message: `Invalid ${item.label}`,
				}
			);
		} else if (item.key === 'business_type') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'dfirstname0') {
			rules.push(
				{
					required: true,
					message: `Kindly input ${item.label}`,
				},
				{
					pattern: /^[a-z\-_\s]+$/i,
					message: `Invalid ${item.label}`,
				}
			);
		} else if (item.key === 'ddob0') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'city') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'loan_amount') {
			rules.push(
				{
					required: true,
					message: `Kindly input ${item.label}`,
				},
				{
					pattern: /^[0-9]{6,10}$/,
					message: `Invalid ${item.label}`,
				}
			);
		} else if (item.key === 'annual_revenue') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'emiBounce_count') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		} else if (item.key === 'unsecuredLoan_count') {
			rules.push({
				required: true,
				message: `Kindly input ${item.label}`,
			});
		}
		return rules;
	};

	onNext = () => {
		if (this.state.currentStep === 2) {
			this.props.form.validateFields(
				[
					'email',
					'mobile',
					'emi_obligation',
					'annual_revenue',
					'gstin',
					'business_started',
					'business_industry_type',
				],
				(errors, values) => {
					if (!errors) {
						let _data = this.state.formData;
						values['business_started'] = moment(values['business_started']).format('YYYY/MM/DD');
						Object.assign(_data, values);
						this.setState({ isLoading: true, formData: _data });
						let obj = this.props.form.getFieldsValue();
						this.props
							.createUser({
								email: obj.email,
								mobile: obj.mobile,
								request: 'ncbiz_web',
								locality: this.state.formData.city,
								pincode: this.state.formData.pincode,
								state: this.state.formData['state'],
								city: this.state.formData.city,
								usertype: 'Borrower',
								user_origin: 'NC_Biz_Web',
								user_source: 'NC_Biz_Web',
							})
							.then((res) => {
								if (res && res.message && res.message === 'User Exist') {
									this.props.login({ emailid: obj.email, password: obj.mobile }).then((_res) => {
										if (_res && _res.status && _res.status === 'ok') {
											let userid = _res && _res.user && _res.user.id && _res.user.id;
											localStorage.setItem('token', _res.token && _res.token);
											this.setState({
												isLoading: false,
												userid,
												currentStep: this.state.currentStep + 1,
											});
										}
									});
								} else {
									if (res && res.message && res.message === 'User created') {
										this.setState({
											otp1: true,
										});
									}
									this.props.login({ emailid: obj.email, password: obj.mobile }).then((_res) => {
										if (_res && _res.status && _res.status === 'ok') {
											let userid = _res && _res.user && _res.user.id && _res.user.id;
											localStorage.setItem('token', _res.token && _res.token);
											this.setState({
												isLoading: false,
												userid,
												currentStep: this.state.currentStep + 1,
											});
										} else {
											message.error(res && res.message && res.message);
										}
									});
								}
							});
					}
				}
			);
		} else if (this.state.currentStep === 1) {
			this.props.form.validateFields(
				['loan_amount', 'dfirstname0', 'ddob0', 'business_name', 'business_type', 'pincode', 'city', 'state'],
				(errors, values) => {
					values['ddob0'] = moment(values.ddob0).format('YYYY/MM/DD');
					if (!errors) {
						this.setState({
							formData: values,
							currentStep: this.state.currentStep + 1,
						});
					}
				}
			);
		}
	};
	onPrevious = () => {
		this.setState(
			{
				currentStep: this.state.currentStep - 1,
			},
			() => {
				if (this.state.currentStep === 1) {
					let _step1 = this.state.Step1;
					Object.keys(this.state.formData).forEach((item) => {
						let index = _step1.findIndex((previtem) => previtem.key === item);

						if (index !== -1) {
							_step1[index]['default_value'] = this.state.formData[item];
						}
					});
					this.setState({
						Step1: _step1,
					});
				} else if (this.state.currentStep === 2) {
					let _step2 = this.state.Step2;
					Object.keys(this.state.formData).forEach((item) => {
						let index = _step2.findIndex((previtem) => previtem.key === item);
						if (index !== -1) {
							_step2[index]['default_value'] = this.state.formData[item];
						}
					});
					this.setState({
						Step2: _step2,
					});
				}
			}
		);
	};

	closeModal = () => {
		this.setState({
			isModal: false,
		});
	};

	getAmountUm = (a) => {
		if (a >= 99999 && a <= 9999999) {
			return 'Lakhs';
		} else if (a <= 999999999 && a >= 1000000) {
			return 'Crores';
		}
	};

	getAmount = (a) => {
		if (a >= 99999 && a <= 9999999) {
			return a / 100000;
		} else if (a <= 999999999 && a >= 1000000) {
			return a / 10000000;
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, _temp) => {
			if (this.state.KYC.length === 0) {
				message.error('Pan card Required');
			} else {
				if (!err) {
					let _form = this.state.formData;
					Object.assign(_form, _temp);
					this.setState(
						{
							formData: _form,
						},
						() => {
							let values = this.state.formData;

							let obj = {
								Business_details: {
									business_industry_type: values.business_industry_type,
									business_name: values.business_name,
									business_started: values.business_started,
									business_type: values.business_type,
									business_email: values.email,
									gstin: values.gstin,
									contact: values.mobile,
								},
								userid: this.state.userid,
								loan_details: {
									origin: 'NC_Biz_Web',
									emiBounce_count: values['emiBounce_count'],
									loan_amount: this.getAmount(values.loan_amount),
									loan_amount_um: this.getAmountUm(values['loan_amount']),
									unsecuredLoan_count: values['unsecuredLoan_count'],
									white_label_id: 1,
									loan_request_type: 1,
									loan_tenure: 3,
									loan_tenure_um: 'Lakhs',
									loan_type_id: '1',
									loan_usage_type_id: 1,
									loan_product_id: '74',
									CIBIL_check: values['CIBIL_check'] ? '1' : '0',
									cur_monthly_emi: values['emi_obligation'],
								},
								financials: {
									annual_revenue: this.getAmount(values.annual_revenue),
									revenue_um: this.getAmountUm(values['annual_revenue']),
									annual_op_expense: 1,
									op_expense_um: 'Lakhs',
								},
								Collaterals: {
									assets_value: 1,
									assets_value_um: 'Lakhs',
									property_type: 8,
								},
								businessaddress: {
									city: values.city,
									pincode: values.pincode,
									state: values.state,
									locality: 'nan',
									line1: 'nan',
								},
								director_details: {
									director_0: {
										ddob0: values.ddob0,
										dfirstname0: values.dfirstname0,
										dcibil_remarks0: values['cibil_remarks'],
									},
								},
								documents: {
									KYC: this.state.KYC,
									financials: this.state.financials,
									others: this.state.others,
								},
							};
							this.props.applyLoan(obj).then((res) => {
								if (res && res.status && res.status === 'ok') {
									message.success(res && res.message && res.message);
									Modal.confirm({
										okButtonProps: { id: 'install_app' },
										cancelButtonProps: { id: 'cancel_app' },
										okText: ' Install Namaste Biz',
										maskClosable: true,
										cancelText: 'Cancel',
										content:
											'Your loan application is created! Login to Namaste Biz App to check status.',
										onCancel: () => {
											this.onReject();
										},
										onOk() {
											window.location.replace(
												'https://play.google.com/store/apps/details?id=com.nc.neobanking'
											);
										},
									});
								} else {
									message.error(res && res.message && res.message);
								}
							});
						}
					);
				}
			}
		});
	};

	pinCodeChange = (pincode) => {
		if (pincode && pincode.toString() && pincode.toString().length === 6) {
			this.setState({
				isPinLoading: true,
			});
			this.props.getCityState(pincode).then((res) => {
				if (
					(res && res.district && res.district.length === 0) ||
					(res && res.state && res.state.length === 0) ||
					(res && res.locality && res.locality.length === 0)
				) {
					this.setState({ isPinLoading: false });
					message.error('Knidly try again');
				} else {
					this.setState({ isPinLoading: false, isPinValid: true });
					let district =
						res &&
						res.district &&
						res.district &&
						res.district[0] &&
						res.district[0].value &&
						res.district[0].value;
					let locality = res && res.locality && res.locality;
					let _state = res && res.state && res.state && res.state[0] && res.state[0].value;
					this.setState({ district, locality, _state });
				}
			});
		}
	};

	handleOtpChange = (e) => {
		this.setState(
			{
				otp: e.target.value,
				otpDisabled: false,
			},
			() => {
				if (this.state.otp.length === 4) {
					let mObj = this.props.form.getFieldsValue();
					this.props
						.verifyOtp({
							mobile: mObj.mobile,
							otp: this.state.otp,
							os: 'web',
							device_id: 'nan',
							fcm_id: 'Nan',
						})
						.then((res) => {
							if (res && res.status && res.status === 'ok') {
								let _step2 = this.state.Step2;
								_step2.forEach((item) => {
									if (item.key === 'mobile' || item.key === 'otp' || item.key === 'button') {
										item.disabled = true;
									}
								});
								this.setState({
									isValidOtp: true,
									Step2: _step2,
								});
							} else {
								message.error(res && res.message && res.message);
							}
						});
				}
			}
		);
	};

	onReject = () => {
		window.location.reload();
	};

	render() {
		return (
			<div className='bizz-landing-wrapper'>
				<div className='bizz-section1'>
					<div className='logo-wrapper'>
						<img alt='imag' style={{ width: '15rem', height: '11rem' }} src={images['Logo']} />
					</div>
					<div className='section1-content'>
						<div className='section1-left'>
							<h1 className='section1-text'>
								Get Business Loans <br /> From 70+ Banks & NBFCs
							</h1>
							<img className='section1-image' src={images['Vector']} alt='BgImage' />
						</div>
						<div className='card-wrapper'>
							<div className='card-badge'>
								<Badge
									count={`${this.state.currentStep}/3`}
									style={{
										backgroundColor: 'green',
									}}
								/>
							</div>
							<Form>
								{this.state.currentStep === 1 && (
									<div className='card-wrapper-step1'>
										{this.state.Step1.map((item) => {
											return this.getFormBody(item);
										})}
									</div>
								)}
								{this.state.currentStep === 2 && (
									<div className='card-wrapper-step2'>
										{this.state.Step2.map((item) => {
											return this.getFormBody(item);
										})}
									</div>
								)}
								{this.state.currentStep === 3 && (
									<div className='card-wrapper-step3'>
										{this.state.Step3.map((item) => {
											return this.getFormBody(item);
										})}
									</div>
								)}
								<div className='button-section'>
									<>
										<Button
											id='previous'
											disabled={this.state.currentStep === 1 && true}
											onClick={this.onPrevious}
										>
											Previous
										</Button>
										{this.state.currentStep === 3 && (
											<Button
												style={{
													borderRadius: '2rem',
													backgroundColor: 'green',
													color: 'white',
												}}
												className='btn_submit'
												id='submit'
												onClick={this.handleSubmit}
											>
												Submit
											</Button>
										)}
										{this.state.currentStep === 1 && (
											<Button
												id='next_1'
												loading={this.state.isLoading}
												disabled={
													this.state.currentStep === 2
														? this.state.isValidOtp
															? false
															: true
														: this.state.currentStep === 1
														? !this.state.isPinValid
														: null
												}
												style={{
													borderRadius: '2rem',
													backgroundColor: 'green',
													color: 'white',
												}}
												onClick={this.onNext}
											>
												Next
											</Button>
										)}
										{this.state.currentStep === 2 && (
											<Button
												id='next_2'
												loading={this.state.isLoading}
												disabled={
													this.state.currentStep === 2
														? this.state.isValidOtp
															? false
															: true
														: this.state.currentStep === 1
														? !this.state.isPinValid
														: null
												}
												style={{
													borderRadius: '2rem',
													backgroundColor: 'green',
													color: 'white',
												}}
												onClick={this.onNext}
											>
												Next
											</Button>
										)}
									</>
								</div>
							</Form>
						</div>
					</div>
				</div>
				<div className='bizz-section2'>
					<h1 className='section2-heading'>How It Works</h1>
					<div className='section2-cards'>
						{text.map((item) => {
							return (
								<div className='section2-text'>
									<div className='card-image'>
										<img
											alt='ima'
											style={{ height: '8rem', marginLeft: '2rem' }}
											src={item.image}
										/>
									</div>
									<h2 className='card-heading'>{item.heading}</h2>
									<p className='card-content'>{item.text}</p>
								</div>
							);
						})}
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		{ getLoanProducts, createUser, login, applyLoan, getCityState, verifyOtp, trigerOtpSend },
		dispatch
	);
}

export default connect(
	null,
	matchDispatchToProps
)(Form.create({ name: 'bizlandinf' })(BizLanding));
