import React from 'react';

import images from '../../../utils/helper/imageurls';
import './index.scss';

function footer() {
	return (
		<div className='biz-footer-wrapper'>
			<p className='address'>
				Namaste Credit – # 5, Crimson Court,
				<br /> Ground Floor and First Floor, <br />
				Jeevan Bima Nagar Main Road, <br />
				Bengaluru, Karnataka, India 560075 <br />
				<br />
				Customer Support +91 8080809068
			</p>
			<div className='content'>
				<p className='biz-text'>Business loans up to Rs 10 Crore*</p>
				<p className='biz-text'>Interest rates starting from 12%* pa</p>
				<p
					style={{ cursor: 'pointer' }}
					className='biz-city'
					onClick={() => window.open('https://namastebiz.com/terms-and-conditions-loans/', '_blank')}
				>
					Terms and Conditions Apply{' '}
				</p>
				<p
					style={{ cursor: 'pointer' }}
					className='biz-city'
					onClick={() => window.open('https://namastebiz.com/biz_privacy_policy/', '_blank')}
				>
					Read Privacy Policy{' '}
				</p>
			</div>
			<div className='biz-logo'>
				<img
					style={{ backgroundColor: 'white', borderRadius: '3rem', height: '14rem', width: '23rem' }}
					src={images['Namaste_Logo']}
					alt='logo-nc-biz'
				/>
			</div>
		</div>
	);
}

export default footer;
