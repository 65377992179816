import CryptoJS from 'crypto-js';
import crypto from 'crypto';
import { axiosAPI } from '../../shared/utils/helper/baseUrls';
import {
	ERROR,
	CHECKLIST,
	NC_STATUS,
	UPLOAD_SELECTED_LOAN,
	UPLOAD_DISBURSEMENT_DOC,
	SETTING_USER_ID,
	SETTING_SANCTION_AMOUNT,
	SETTING_SANCTION_AMOUNT_UM,
	LOAN_LIST,
	LOAN_LIST_UPDATE
} from '../actionTypes';
import { secret } from '../../shared/utils/helper/getWhitelabel';

export function getLoanProducts() {
	return dispatch =>
		axiosAPI()
			.get('/loanproducts')
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getLoanProductDetails(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanproducts/${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function getDocumentTypes(loan_product, business_type) {
	return dispatch =>
		axiosAPI()
			.post(`/loan/documentTypes/`, { loan_product: loan_product, business_type: business_type })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getLoanProductType(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanproducttype?loan_product_id=${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function doCheckInList(id) {
	return dispatch => {
		dispatch({ type: CHECKLIST, payload: id });
	};
}

export function viewLoanListing(skip, searchValue, canceleToken) {
	let search = searchValue === undefined ? '' : searchValue;
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/viewloanlisting?status1=1&skip=${skip}&limit=5&search=${search}`, {
				cancelToken: canceleToken
			})
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					let loanList = getState()['loan'].loanList;
					loanList.forEach(i => {
						getPendingDocCounts(i, getState, dispatch);
					});
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewLoanListingDisbursed(skip) {
	let status = {
		status1: 2,
		status2: 9,
		status3: 12,
		status4: 12
	};
	return dispatch =>
		axiosAPI()
			.get(
				`/loanlisting?skip=${skip}&limit=15&status1=${status.status1}&status2=${status.status2}&status3=${status.status3}&status4=${status.status4}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
			});
}

export function getloanBankMapping(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanbankmappingdetails?loan_id=${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getLoanSanction(loanid, loan_bank_mapping_id) {
	return dispatch =>
		axiosAPI()
			.get(`/loansanction/?loan_id=${loanid}&&loan_bank_mapping_id=${loan_bank_mapping_id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getNCStatusManage() {
	const user = localStorage.getItem('user');
	const whiteLabelId = JSON.parse(user).white_label_id.split(',');
	return dispatch =>
		axiosAPI()
			.get(`/ncstatusmanage?white_label_id=${whiteLabelId[0]}`)
			.then(res => {
				dispatch({ type: NC_STATUS, payload: res.data });
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewLoanApplication(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanrequest/${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function applyLoan(loan) {
	let permission = JSON.parse(localStorage.getItem('permission')).dashboard;
	return dispatch =>
		axiosAPI()
			.post(permission.case_creation === true ? '/casecreation_uiux' : '/loan/createloan', loan)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getSummaryData(loanId) {
	return axiosAPI()
		.get(`/jsonExtractionFetch?loanId=${loanId}`)
		.then(res => res)
		.catch(err => {
			console.log(err);
		});
}

export function getProfiledata() {
	return dispatch =>
		axiosAPI()
			.get(`/profile/`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewDocument(docName, userid) {
	return dispatch =>
		axiosAPI()
			.post(`/viewDocument/`, { filename: docName, userid: userid })
			.then(res => {
				const t = data => {
					var rawData = CryptoJS.enc.Base64.parse(data);
					var key = CryptoJS.enc.Latin1.parse(secret);
					var iv = CryptoJS.enc.Latin1.parse(secret);
					var plaintextData = CryptoJS.AES.decrypt({ ciphertext: rawData }, key, { iv: iv });
					var plaintext = plaintextData.toString(CryptoJS.enc.Latin1);
					res.data.signedurl = plaintext;
				};
				t(res.data.signedurl);
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateDisbursement(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/loandisbursement/update/`, obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateSanction(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/loansanction/update`, obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function uploadSelectedLoan(selectedLoan) {
	return dispatch => {
		dispatch({ type: UPLOAD_SELECTED_LOAN, payload: selectedLoan });
	};
}

export function uploadedDisbursementDocuments(documentsArray) {
	return dispatch => {
		dispatch({ type: UPLOAD_DISBURSEMENT_DOC, payload: documentsArray });
	};
}

export function settingUserId(userId) {
	return dispatch => {
		dispatch({ type: SETTING_USER_ID, payload: userId });
	};
}

export function settingSanctionAmount(san_amt) {
	return dispatch => {
		dispatch({ type: SETTING_SANCTION_AMOUNT, payload: san_amt });
	};
}
export function settingSanctionAmountUnit(san_amt_um) {
	return dispatch => {
		dispatch({ type: SETTING_SANCTION_AMOUNT_UM, payload: san_amt_um });
	};
}

export function getLenderDocuments() {
	return dispatch =>
		axiosAPI()
			.get(`/lender/doctype`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function uploadBorrowerDocuments(obj, type, loanId) {
	return (dispatch, getState) =>
		axiosAPI()
			.post(`/borrowerdoc-upload`, { upload_document: obj })
			.then(res => {
				if (type === 'DB_BANK_UPLOAD') {
					let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
					let index = loanList.findIndex(item => item.id === loanId);
					if (index !== -1) {
						loanList[index]['dbDocCheck'] = 'Uploaded';
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
						return res.data;
					}
				} else {
					return res.data;
				}
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function uploadLenderDocuments(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/lenderdoc-upload`, { upload_document: obj })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function editLoans(loan) {
	return dispatch =>
		axiosAPI()
			.post(`/loan/edit`, loan)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function acceptRejectLoans(status, loan_bank_mapping_id, loan_id) {
	return dispatch =>
		axiosAPI()
			.post(`/loanAcceptReject`, {
				status: status,
				loan_bank_mapping_id: loan_bank_mapping_id,
				loan_id: loan_id
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function removeDocuments(document) {
	return dispatch =>
		axiosAPI()
			.post(`/documentDelete`, document)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewInProgressLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status1=2&status2=8&skip=${skip}&limit=5&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function searchLoanListing(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?skip=${skip}&limit=5&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewAssignedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=9,12&status4=2,3,10&skip=${skip}&limit=5&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function postGiveOffer(value) {
	return dispatch =>
		axiosAPI()
			.post('giveOffer', value)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function addDisbursment(addDisbursementData) {
	return dispatch =>
		axiosAPI()
			.post('/add_Disbursement', addDisbursementData)
			.then(res => {
				if (res && res.data) {
					return res.data;
				}
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function scheduleMeeting(meetingData) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/meetingSchedule', meetingData)
			.then(res => {
				let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
				let index = loanList.findIndex(item => item.id === res.data.data[0]['loan_id']);
				if (index !== -1) {
					loanList[index]['loan_bank_mapping'] = res.data.data[0]['loan_bank_mapping'];
					loanList[index].isCardOpen = !loanList[index].isCardOpen;
					dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
				}
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function acceptMeeting(acceptMeetingData) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/acceptMeeting', acceptMeetingData)
			.then(res => {
				let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
				let index = loanList.findIndex(item => item.id === res.data.data[0]['loan_id']);
				if (index !== -1) {
					loanList[index]['loan_bank_mapping'] = res.data.data[0]['loan_bank_mapping'];
					dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
				}

				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function lenderStatus() {
	return dispatch =>
		axiosAPI()
			.get('lenderStatus')
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function changeLenderStatus(data) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('changeLenderStatus', data)
			.then(res => {
				let loanList = getState()['loan'].loanList;
				loanList.forEach(item => {
					if (item.loan_bank_mapping.id === res.data['loanbankmapping'].id) {
						let index = loanList.indexOf(item);
						loanList[index].loan_bank_mapping = res.data['loanbankmapping'];
						loanList[index].loan_bank_mapping.lender_status = res.data['loanbankmapping'].lender_status;
						loanList[index].loan.remark_history = res.data['loanstatuscomments'].comment_text;
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					}
				});
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function clearLoanList() {
	return dispatch => dispatch({ type: LOAN_LIST_UPDATE, payload: [] });
}

export function viewQueryRaisedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status1=8&status2=12&skip=${skip}&limit=5&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewCompletedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status1=2&status2=9&status3=9&status4=2&skip=${skip}&limit=5&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewRejectedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status1=7&status2=13&skip=${skip}&limit=5&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function initiateDocument(case_id, status) {
	return (dispatch, getState) => {
		let loanList = getState()['loan'].loanList;
		loanList.forEach(item => {
			if (item.loan.loan_ref_id === case_id) {
				item.isInitiateLoading = true;
				dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
			}
		});
		return axiosAPI()
			.post(`casecreation-docStatus_initiate`, {
				case_id,
				status
			})
			.then(res => {
				if (res && res.data) {
					loanList.forEach(item => {
						if (item.loan.loan_ref_id === res.data.data.loan_ref_id) {
							let index = loanList.indexOf(item);
							loanList[index].loan.document_upload = 'Done';
							loanList[index].isInitiateLoading = false;
							dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
						}
					});
					return res.data;
				}
			})
			.catch(err => {
				loanList.forEach(item => {
					if (item.loan.loan_ref_id === case_id) {
						item.isInitiateLoading = false;
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					}
				});
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
	};
}

export function viewReassignedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return (dispatch, getState) =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=10&status4=4&status5=null&status6=null&skip=${skip}&limit=5&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					let loanList = getState()['loan'].loanList;
					loanList.forEach(i => {
						getPendingDocCounts(i, getState, dispatch);
					});
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getManager(loanId) {
	return dispatch =>
		axiosAPI()
			.post('/creditManagerList', { loanId })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateCreditManager(loanId, manager_userid, emails) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/updateCreditManager', { loanId, manager_userid, emails: emails ? emails : [] })
			.then(res => {
				let loanList = getState()['loan'].loanList;
				loanList.forEach(item => {
					if (item.loan.id === loanId) {
						item.sales_id = res.data.data;
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					}
				});
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function reAssignedToNc(loanId, loanBankId, comments) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/loanReassignToNc', { loanId, loanBankId, comments })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getEncryptedWL(loanId, loanBankId, comments) {
	return (dispatch, getState) =>
		axiosAPI()
			.get('/case-whitelabelEncrypt')
			.then(async res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getAnalyzerDocument(caseId, wid) {
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/uploaded_doc_list?case_id=${caseId}&white_label_id=${wid}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function downloadZipDocuments(userid, loandocs) {
	return (dispatch, getState) =>
		axiosAPI()
			.post(`/getzipdocument`, { userid, loandocs, tag: 'loan_doc' })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function getLoanCounts(userid, loandocs) {
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/loanStatusCounts`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

function getPendingDocCounts(loan, getState, dispatch) {
	let reqBody = {
		loan_id: loan.loan.id,
		loan_ref_id: loan.loan.loan_ref_id,
		loan_documents: loan.loan_document,
		businessType: loan.business.businesstype,
		product_id: loan.loan_products.id
	};
	axiosAPI()
		.post(`/pendingCount`, reqBody)
		.then(res => {
			if (res.data.status === 'ok') {
				let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
				let index = loanList.findIndex(item => item.loan.loan_ref_id === res.data.data['loan_ref_id']);
				if (index !== -1) {
					loanList[index].pending_doc_count = res.data.data['pendingDoc_count'];
					dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					return;
				}
				return;
			}
			return;
		})
		.catch(err => {
			dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			return true;
		});
}

export function updateCinRoc(data) {
	return dispatch =>
		axiosAPI()
			.post(`/cin-update`, data)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function checkCaseCreated(cin) {
	return dispatch =>
		axiosAPI()
			.get(`/loan_status?cin_number=${cin}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function getEligibilityProject() {
	return dispatch =>
		axiosAPI()
			.get('/eligiblityProductList')
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}
