import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import MainReducer from '../reducers';
// import ReduxPromise from 'redux-promise';

export default function configureStore(initialState) {
	const store = createStore(
		MainReducer,
		initialState,
		compose(
			applyMiddleware(thunk),
			window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
		)
	);
	return store;
}
