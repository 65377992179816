import React, { Component } from 'react';
import Lottie from 'lottie-react-web';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import './index.scss';
import images from '../../shared/utils/helper/imageurls';
import Login from '../Login';
import FAQ from '../../shared/components/FAQ';
import Signin from '../Signin';
import NCLogo from '../../shared/constants/NCLogo.json';
import { checkUserExists, whiteLabel } from '../../redux/actions';
import { axiosAPI } from '../../shared/utils/helper/baseUrls';
import { getCustomUrl } from './../../App';

class LandingPage extends Component {
	state = {
		gettingToken: false,
	};
	componentDidMount() {
		let token = this.getQueryStringValue('ZzdkYUprUXNOMUd3Mm1HeTJ5Q1pvQT09');
		let productId = this.getQueryStringValue('ncbiz');
		let urlParamsObj = new URLSearchParams(window.location.search);
		if (urlParamsObj.get('change_password') === 'yes') {
			localStorage.setItem('pwd_chng', 'yes');
		}
		if (token === '') {
			this.setState({ gettingToken: true });
		} else {
			localStorage.setItem('token', token);
			this.setWhiteLabelId(window.location.href);
			this.props.checkUserExists().then((res) => {
				if (res && res.status === 401) {
					this.props.history.push('');
					this.setState({ gettingToken: true });
				} else {
					if (res === undefined) {
						message.error('Something went wrong!');
					} else {
						if (res.usertype && res.usertype === 'Sales') {
							this.props.history.push(getCustomUrl() + '/sales/home');
						} else {
							this.props.whiteLabel().then((res) => {
								if (res) {
									if (productId === '') {
										this.goToDashboard();
									} else {
										this.goToLoanApplication(productId);
									}
								} else {
									message.error('Something went wrong!');
								}
							});
						}
					}
				}
			});
		}
	}
	goToLoanApplication = (productId) => {
		const type = 'create';
		this.props.history.push(
			getCustomUrl() + `/main/loanlist/loanapplications/${type}/${btoa(productId)}/${btoa(1)}/${btoa(1)}`
		);
	};
	getQueryStringValue = (key) => {
		return decodeURIComponent(
			window.location.search.replace(
				new RegExp(
					'^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
					'i'
				),
				'$1'
			)
		);
	};
	goToDashboard = () => {
		this.props.history.push(getCustomUrl() + '/main/dashboard');
	};

	setWhiteLabelId = async (urlHref) => {
		try {
			let b = urlHref.split('&');
			let c = b[b.length - 1];
			let idEN = c.split('label_id=');
			let id = atob(String(idEN[idEN.length - 1]));
			let data = await axiosAPI().get(`whitelabelsolution/${id}`);
			if (data.data.status === 'ok') {
				data.data.permission.white_label &&
					data.data.permission.white_label.site_url &&
					localStorage.setItem('origin_url', data.data.permission.white_label.site_url);
			}
		} catch (error) {
			console.log('Silte url ...');
		}
	};

	render() {
		const { gettingToken } = this.state;
		if (!gettingToken)
			return (
				<Lottie
					options={{
						animationData: NCLogo,
					}}
					width={500}
				/>
			);
		else {
			return (
				<div>
					<div className='landing-page-wrapper'>
						<div className='landing-page-first-section'>
							<div className='landing-page-logo'>
								<img src={images['NCLogo']} alt='namaste-credit-logo' />
							</div>
							<div className='landing-page-text'>We are now connected to  50 Banks!</div>
						</div>
						<div className='landing-page-second-section'>
							<div className='landing-page-info'>
								<div className='landing-page-info-text-1'>
									Getting business loans just got easier.  Across 25 locations borrowers have a
									network of Loan partners and Loan Specialists who can connect to  2500+ cities and
									towns pan India
								</div>
								<div className='landingpage-faq'>
									<FAQ />
								</div>
							</div>
							<div className='landing-page-login'>
								{this.props.location.pathname === '/signup' ? <Signin /> : <Login />}
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			checkUserExists,
			whiteLabel,
		},
		dispatch
	);
}

export default withRouter(
	connect(
		null,
		matchDispatchToProps
	)(LandingPage)
);
