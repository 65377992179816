import {
	CHECKLIST,
	NC_STATUS,
	UPLOAD_SELECTED_LOAN,
	UPLOAD_DISBURSEMENT_DOC,
	SETTING_USER_ID,
	SETTING_SANCTION_AMOUNT,
	SETTING_SANCTION_AMOUNT_UM,
	LOAN_LIST,
	LOAN_LIST_UPDATE
} from '../actionTypes';

const initialState = {
	checkedDocuments: [],
	nc_Status: [],
	uploadDisbursementDocuments: [],
	uploadSelectedLoan: {},
	userId: '',
	san_amt: null,
	san_amt_um: null,
	loanList: []
};
export function loan(state = initialState, action) {
	switch (action.type) {
		case CHECKLIST:
			return { ...state, checkedDocuments: action.payload };
		case NC_STATUS:
			return { ...state, nc_Status: action.payload };
		case UPLOAD_SELECTED_LOAN:
			return { ...state, uploadSelectedLoan: action.payload };
		case UPLOAD_DISBURSEMENT_DOC:
			return { ...state, uploadDisbursementDocuments: action.payload };
		case SETTING_USER_ID:
			return { ...state, userId: action.payload };
		case SETTING_SANCTION_AMOUNT:
			return { ...state, san_amt: action.payload };
		case SETTING_SANCTION_AMOUNT_UM:
			return { ...state, san_amt_um: action.payload };
		case LOAN_LIST:
			state.loanList = [];
			return { ...state, loanList: state.loanList.concat(action.payload) };
		case LOAN_LIST_UPDATE:
			return { ...state, loanList: action.payload };
		default:
			return state;
	}
}

export function ncStatus(state, action) {
	switch (action.type) {
		case NC_STATUS:
			return action.payload;
		default:
			return state;
	}
}
