import React from 'react';

import './index.scss';

export default function FAQ() {
	return (
		<div className='faq-section-container'>
			<div className='faq-title'>Frequently Asked</div>
			<div className='faq-items'>
				<div className='faq-question'>
					How can namaste credit make sure the confidentiality of my customers are maintained?
				</div>
				<div className='faq-answer'>
					Your customers’ PAN numbers are closely connected with your account. Incase your customer approaches
					us directly, we will inform you and loop you back in.
				</div>
			</div>
			<div className='faq-items'>
				<div className='faq-question'>When do I get paid?</div>
				<div className='faq-answer'>
					We are paid once the loan gets dispersed. However no matter the dates we get paid, you will be paid
					1 week from the date the lender confirms the disbersement.
				</div>
			</div>
			<div className='faq-items'>
				<div className='faq-question'>How are you different from other players?</div>
				<div className='faq-answer'>
					Namaste Credit is one of its kind. We are a true blue marketplace. We look to onboarding many banks.
					Hence all banks whose policies align with your loan application will have a chance to view your
					application. We are transperant and track the process so that you can be informed.
				</div>
			</div>
		</div>
	);
}
