import axios from 'axios';
import { fakeAuth } from './RouteHelper';

import ES6Promise from 'es6-promise';
ES6Promise.polyfill();

export function getUrl() {
	if (process.env.NODE_ENV === 'localhost') {
		return 'https://apiver4.namastecredit.com/';
	} else {
		return 'https://apiver4.namastecredit.com/';
	}
}

export function axiosAPI() {
	return axios.create({
		baseURL: 'https://apiver4.namastecredit.comi/',
		withCredentials: true,
		headers: {
			Authorization: `Bearer ${fakeAuth.getToken()}`
		}
	});
}

export function getApiServiceUrl() {
	if (process.env.NODE_ENV === 'localhost') {
		return 'http://40.80.80.135:1337';
	} else {
		return 'http://40.80.80.135:1337';
	}
}
