import HDFC from '../images/hdfc-logo.jpg';
import PhoneIcon from '../images/phone-icon.png';
import NCLogo from '../images/namastecredit-logo.png';
import Financials from '../images/financials.svg';
import Payments from '../images/payments.svg';
import BusinessLoans from '../images/business-loans.svg';
import SalariedLoans from '../images/salarized-loans.svg';
import WarningIcon from '../images/circle-alert.svg';
import Blast from '../images/blast.svg';
import Mail from '../images/mail.svg';
import InfoIcon from '../images/InfoIcon.svg';
import RejectedIcon from '../images/RejectedIcon.svg';
import SuccessIcon from '../images/SuccessIcon.svg';
import BusinessLoan from '../images/Business Loan-01.svg';
import SalariedLoan from '../images/Salaried loan-01.svg';
import CA from '../images/ca.svg';
import RealEstateAgent from '../images/real-estate.svg';
import RupeeIcon from '../images/rupeeIcon.svg';
import EmptyProfilePic from '../images/Empty_Profile_Pic.png';
import Gaurav from '../images/gaurav.jpg';
import Call from '../images/call.svg';
import User from '../images/user.svg';
import BusinessAccount from '../images/business account.svg';
import SalariedAccount from '../images/salaried account.svg';
import AddDisbursment from '../images/add-disbursment.svg';
import Vector from '../images/vector.png';
import Logo from '../images/logo.png';
import Icon1 from '../images/icon-1.png';
import Icon2 from '../images/icon-2.png';
import Icon3 from '../images/icon-3.png';
import Icon4 from '../images/icon-4.png';
import Namaste_Logo from '../images/Namaste_Logo.png';
import KARNATAKA from '../images/KARNATAKA.jpg';

import Telangana from '../images/Telangana.png';
import GUJARAT from '../images/GUJARAT.jpg';
import UTTARPRADESH from '../images/UTTAR PRADESH.jpg';
import MAHARASHTRA from '../images/MAHARASHTRA.jpg';
import MIZORAM from '../images/MIZORAM.jpg';
import RAJASTHAN from '../images/RAJASTHAN.jpg';
import KERALA from '../images/KERALA.png';
import MADHYAPRADESH from '../images/MADHYA PRADESH.png';
import UTTARAKHAND from '../images/UTTARAKHAND.jpg';
import HARYANA from '../images/HARYANA.jpg';
import PUNJAB from '../images/PUNJAB.jpg';
import ANDHRAPRADESH from '../images/ANDHRA PRADESH.png';
import JAMMUKASHMIR from '../images/JAMMU & KASHMIR.jpg';
import ORISSA from '../images/ORISSA.jpg';
import BIHAR from '../images/BIHAR.jpg';
import TAMILNADU from '../images/TAMIL NADU.jpg';
import WESTBENGAL from '../images/WEST BENGAL.jpg';
import RISK1 from '../images/risk-1.png';
import RISK2 from '../images/risk-2.png';
import RISK3 from '../images/risk-3.png';

const images = {
	HDFC: HDFC,
	PhoneIcon: PhoneIcon,
	NCLogo: NCLogo,
	Financials: Financials,
	Payments: Payments,
	BusinessLoans: BusinessLoans,
	SalariedLoans: SalariedLoans,
	WarningIcon: WarningIcon,
	Blast: Blast,
	Mail: Mail,
	InfoIcon: InfoIcon,
	RejectedIcon: RejectedIcon,
	SuccessIcon: SuccessIcon,
	BusinessLoan: BusinessLoan,
	SalariedLoan: SalariedLoan,
	CA: CA,
	RealEstateAgent: RealEstateAgent,
	RupeeIcon: RupeeIcon,
	EmptyProfilePic: EmptyProfilePic,
	Gaurav: Gaurav,
	Call: Call,
	User: User,
	BusinessAccount: BusinessAccount,
	SalariedAccount: SalariedAccount,
	AddDisbursment: AddDisbursment,
	Icon1: Icon1,
	Icon2,
	Icon3,
	Icon4,
	Vector,
	Logo,
	Namaste_Logo,
	KARNATAKA,
	Telangana,
	GUJARAT,
	UTTARPRADESH,
	MAHARASHTRA,
	MIZORAM,
	RAJASTHAN,
	KERALA,
	MADHYAPRADESH,
	UTTARAKHAND,
	HARYANA,
	PUNJAB,
	ANDHRAPRADESH,
	JAMMUKASHMIR,
	ORISSA,
	BIHAR,
	TAMILNADU,
	WESTBENGAL,
	RISK1,
	RISK2,
	RISK3,
};

export default images;
