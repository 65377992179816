import { USER, WHITELABEL } from '../actionTypes';

export function user(state = {}, action) {
	switch (action.type) {
		case USER:
			return action.payload;
		default:
			return state;
	}
}

export function whiteLabels(state = {}, action) {
	switch (action.type) {
		case WHITELABEL:
			return action.payload;
		default:
			return state;
	}
}
