import React, { Component, Suspense, lazy } from 'react';
import 'babel-polyfill';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { Row, message } from 'antd';

import './App.scss';
import NCLoader from './shared/components/NCLoader';
import NetworkDetector from './shared/components/NetworkDetector';
import LandingPage from '../src/modules/LandingPage';
import BizLanding from './shared/components/BizLanding';
const CaRouter = lazy(() => import('./shared/routing/CaRouter'));
const PrivateRoute = lazy(() => import('./shared/components/PrivateRoute'));
const PageNotFound = lazy(() => import('./shared/components/PageNotFound'));
const Sales = lazy(() => import('./modules/Sales'));

export const getCustomUrl = () => '/newui';

class App extends Component {
	render() {
		return (
			<Suspense
				fallback={
					<Row type='flex' style={{ height: '100%' }} align='middle' justify='center'>
						<NCLoader />
					</Row>
				}
			>
				<BrowserRouter>
					<Switch>
						{this.props.isDisconnected && message.error('Limited or no connectivity')}
						<Route exact path='/' component={LandingPage} />
						<Route exact path={getCustomUrl()} component={LandingPage} />
						<Route exact path={`${getCustomUrl()}/signup`} component={LandingPage} />
						<PrivateRoute path={`${getCustomUrl()}/main`} component={CaRouter} />
						<Route exact path='/namaste_biz' component={BizLanding} />
						<PrivateRoute path={`${getCustomUrl()}/sales`} component={Sales} />
						<Route path='*' render={() => <PageNotFound />} />
					</Switch>
				</BrowserRouter>
			</Suspense>
		);
	}
}

export default withRouter(NetworkDetector(App));
