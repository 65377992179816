import { combineReducers } from 'redux';
import { error } from './error';
import { user, whiteLabels } from './user';
import { userDetails } from './contacts';
import { loan } from './loan';

const MainReducer = combineReducers({
	error,
	user,
	whiteLabels,
	userDetails,
	loan
});

export default MainReducer;
